<template>
    <div class="my-course">
        <van-sticky>
            <div class="my-course__search">
                <van-search
                    class="my-search"
                    v-model="value"
                    shape="round"
                    placeholder="输入马场或课程套餐名称"
                    @search="onSearch"
                    >
                </van-search>
            </div>
        </van-sticky>
        <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                @load="onLoad1"
                class="my-list"
                >
                <div class="my-course-list">
                    <my-voucher v-for="item in list" :key="item.id" :item="item" :show-tip="item.status" :bool="[0, 1, 2].includes(item.status)">
                        <div slot="content" v-if="item.status === 0 || item.status === 1">
                            <div class="info color">
                                <div class="label">订单总金额</div>
                                <div class="value">￥{{item.amount}}</div>
                            </div>
                            <div class="info color">
                                <div class="label">积分抵扣金额</div>
                                <div class="value">￥{{item.integralAmount}}</div>
                            </div>
                            <div class="info color">
                                <div class="label">余额抵用金额</div>
                                <div class="value">￥{{item.balanceAmount}}</div>
                            </div>
                            <div class="info color last">
                                <div class="label">实际付款金额</div>
                                <div class="value">￥{{item.amount - item.integralAmount- item.balanceAmount}}</div>
                            </div>
                        </div>
                        <div slot="content" v-else>
                            <div class="info color">
                                <div class="label">总鞍时</div>
                                <div class="value">{{item.cardHoursTotal}}鞍时</div>
                            </div>
                            <div class="info color">
                                <div class="label">已使用</div>
                                <div class="value">{{item.cardHoursUsed}}鞍时</div>
                            </div>
                             <div class="info color">
                                <div class="label">已过期</div>
                                <div class="value">{{item.cardHoursOverdue}}鞍时</div>
                            </div>
                            <div class="info color last">
                                <div class="label">可用</div>
                                <div class="value">{{item.cardHoursSurplus}}鞍时</div>
                            </div>
                        </div>
                        <div class="btn" slot="bottom">
                            <div class="tip ellipsis" v-if="item.status !== 0">
                               {{item.dueDay ? `课程套餐还有${item.dueDay}天到期` : ''}}
                            </div> 
                            <van-button round @click="goPay(item)" v-if="item.status === 0 || item.status === 1">去支付</van-button>   
                            <van-button round @click="goClose(item)" v-if="item.status === 0 || item.status === 1">关单</van-button>
                            <van-button round @click="goCourse(item.corpId)" v-if="item.status === 2">去使用</van-button>
                        </div>   
                           
                    </my-voucher>
                </div>
                <no-more v-show="showNoMore && !loading"/>
            </van-list>
            <no-data v-show="showData"/>
        </van-pull-refresh>
        <van-popup class="my-popup" v-model="showGoods">
            <div class="goods">
                <div class="goods-info">
                    <div class="goods-info__img">
                        <van-card
                            :price="totals"
                            title="课程套餐名称"
                            :thumb="imgCard"
                        >
                            <template #num>
                                <van-stepper v-model="num" step="1" />
                            </template>
                        </van-card>
                    </div>
                </div>
                <div class="btn">
                    <div class="car">结算</div>
                    <div class="close" @click="showGoods = false">关闭</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage';
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            api: 'member/bill/cardOrder/cardOrder-list',
            imgCard: require('@/assets/img/card.jpeg'),
            action: 1,
            model: {},
            value: '',
            showGoods: false,
            num: 1,
            price: 123,
        }
    },
    computed: {
        totals() {
            let totals = (this.price * this.num).toFixed(2)
            return totals
        }
    },
    methods: {
        init() {
            this.initList()
        },
        onSearch() {
            this.$set(this.model, 'keyword', this.value)
            this.init()
        },
        goShopping() {
            this.showGoods = true
        },
        goCourse(data) {
            //拿到马场的id
            this.$router.push({path: '/horse/appcourse', query:{
                id: data
            }})
        },
        goPay(data) {
            console.log(data)
            let form = {}
            this.$set(form, 'balanceAmount', data.balance)
            this.$set(form, 'integral', data.integral)
            this.$set(form, 'cardId', data.cardId)
            this.$set(form, 'quantity', data.quantity)
            this.$set(form, 'payAmount', data.payAmount)
            // console.log(form)
            this.$api.post('member/bill/cardOrder/cardOrder-save', form).then(res=>{
                //console.log(res)
                let url = window.encodeURIComponent(`${window.location.origin}/#/center/pay?orderId=${res.id}&orderType=${res.orderPayInfoVO.orderType}`)
                window.location.href = `${res.orderPayInfoVO.jumpGetOpenIdUrl}?returnUrl=${url}`
            })
        },
        goClose(data) {
            this.$help.loading('关单中...')
            this.$api.post('member/bill/cardOrder/cardOrder-close', {id: data.id}).then(res=>{
                if(res.data){
                    this.$help.success('关单成功!')
                    this.init()
                }else{
                    this.$help.error(res.msg)
                }
            })
            .finally(this.$help.loaded)
        }
    },
    mounted() {
        this.init()
    }
    
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .my-course{ 
        .my-pull-refresh{
            margin-top: 20px;
            padding: 0 20px
        }
        &__search {
            // margin: 40px 0 20px;
            padding: 0 20px 0 8px;
            background-color: #fff;
            .van-search__action{
                color: $main;
            }
        }
       
        // &-list{
        //     padding: 20px 20px 0;
        // }
        .my-popup{
            border-radius: 19px;
            .goods {
                height: 35vh;
                width: 300px;
                border-radius: 19px;
                &-info{
                    margin-top: 40px;
                    &__img{
                        border-radius: 19px;
                        img{
                            border-radius: 19px;
                        }
                    }
                }
            }
            .btn{
                margin-top: 30px;
                padding: 0 15px;
                .car{
                    background-color: $yellow;
                    border-radius: 9px;
                    text-align: center;
                    padding: 10px 0;
                    font-size: 16px;
                    //color: #fff;
                    //font-weight: bold;
                }
                .close{
                    text-align: center;
                    margin: 20px 0 0;
                    //margin-top: 20px;
                }
            }
            
        }
    }
</style>